import React, { useContext } from 'react';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import BrandCTA from 'components/BrandCta';
import ContentBlock from 'components/Content/ContentBlock';
import HeadlineBar from 'components/Content/HeadlineBar';
import ColorBlock from 'components/Content/ColorBlock';
import AlertBox from 'components/Content/AlertBox';
import howToUseHCPHeroMobile from '../../../assets/images/NS-HCP-Hero-HowToUse-mobile.png';
// import nascobalSprayProduct from '../../../assets/images/nascobal-spray-label-right.png';

// Images
import step1 from '../../../assets/images/nasal-spray-step1-hcp.png';
import step2 from '../../../assets/images/nasal-spray-step2-hcp.png';
import step3 from '../../../assets/images/nasal-spray-step3-hcp.png';
import scriptNS from '../../../assets/images/script-thumb.png';

// import page style
import './how.scss';

const HowPage = () => {
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  const metaData = appConfigs?.metaData?.hcp;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const pageTitle = 'NASCOBAL® | How To Use NASCOBAL® Nasal Spray';
  const pageDescription = 'Learn how to use NASCOBAL® Nasal Spray';

  const renderPage = () => {
    return (
      <div id="nascobal-hcp-how">
        <ContentBlock id="hcp-how-block">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    How to use{''}
                    <br className="hide-only-mobile" />
                    <span className="yellow">
                      <span
                        className="brandname-nascobal"
                        dangerouslySetInnerHTML={{
                          __html: brandNames?.NASCOBAL,
                        }}
                      />
                    </span>
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={howToUseHCPHeroMobile} />
                </div>
              </Col>
            </Row>

            <ColorBlock blue id="colorblock-nasal-spray">
              <Row>
                <Col xs={12} md={8}>
                  <h2 className="strong">
                    Convenient, once-weekly dosing regimen
                  </h2>
                  <p className="strong">
                    Self-administered:{' '}
                    <span className="blue">
                      1 spray, 1 nostril, 1x a week<sup>1</sup>
                    </span>
                  </p>
                  <ul className="">
                    <li>Convenient, once-weekly dosing regimen</li>
                    <li>Each single-use device delivers one 500 mcg dose</li>
                    <li>Tasteless and odorless fine mist</li>
                  </ul>
                </Col>
                <Col xs={12} md={4} className="center-xs">
                  <img src={scriptNS} className="script-thumb" />
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock white>
              <Row>
                <Col xs={12}>
                  <h2 className="strong">
                    A simple, self-administered, single-use device<sup>1</sup>
                  </h2>
                  <p>
                    Follow the steps below to show your patients how simple it
                    is to use{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames?.NASCOBAL,
                      }}
                    />
                    .
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <img
                    src={step1}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step1-hcp"
                  />
                  <ul>
                    <li>
                      The unit contains only 1 spray:
                      <br />
                      <strong>DO NOT prime before use</strong>
                    </li>
                    <li>Blow nose gently to clear both nostrils</li>
                    <li>
                      Hold the unit with your thumb, supporting it at the
                      bottom, and your index and middle fingers on either side
                      of the nozzle
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={step2}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step2-hcp"
                  />
                  <ul>
                    <li>
                      Gently close 1 nostril with your other index finger.
                      Insert the nozzle into open nostril approximately ½ inch,
                      or as far as feels comfortable, and tilt your head
                      slightly forward. Do not press the plunger yet{' '}
                    </li>
                    <li>
                      Breathe in gently through your nose, close your mouth, and
                      at the same time, press the plunger firmly with your thumb
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={step3}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step3-hcp"
                  />
                  <ul>
                    <li>
                      Remove the nozzle from your nostril. At the same time,
                      keep your head level for 10 to 20 seconds while gently
                      breathing in through your nose and breathing out through
                      your mouth
                    </li>
                  </ul>
                </Col>
              </Row>
              <AlertBox>
                <span
                  className="brandname-nascobal"
                  dangerouslySetInnerHTML={{
                    __html: brandNames.NASCOBAL,
                  }}
                />{' '}
                Nasal Spray should be administered at least one hour before or
                one hour after ingestion of hot foods or liquids.
                <p className="blue">
                  <strong>Dose adjustments may be required.</strong>
                </p>
              </AlertBox>
            </ColorBlock>
            <Row>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/practice-resources"
                  LinkCaption="EXPLORE PRACTICE RESOURCES"
                >
                  EXPLORE PRACTICE RESOURCES
                </BrandCTA>
              </Col>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/request_a_sales_rep"
                  LinkCaption="REQUEST A REPRESENTATIVE"
                >
                  REQUEST A REPRESENTATIVE
                </BrandCTA>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="hcp-b12options">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default HowPage;
